import "isomorphic-fetch";
import * as React from "react";
import * as Sentry from "@sentry/browser";
import App, { Container } from "next/app";
import { SENTRY_TRACKING_ID } from "../src/constants/env";

Sentry.init({
  dsn: SENTRY_TRACKING_ID
});

export default class extends App {
  public componentDidCatch(error: Error, errorInfo: any) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });

    super.componentDidCatch(error, errorInfo);
  }

  public render() {
    const { props } = this as any;
    const { Component, pageProps } = props;
    return (
      <Container>
        <Component {...pageProps} />
        <style jsx global>{`
          @import "tachyons-custom/src/tachyons";
          @import "/src/themes/fonts.css";
          @import "/src/themes/overrides.css";
          @import "/src/themes/browserOverrides.css";
          @import "/src/themes/effects.css";
          @import "/src/themes/customClasses.css";

          #__next {
            display: flex;
            flex-direction: column;
          }
        `}</style>
      </Container>
    );
  }
}
